<template>
  <ul
    class="custom-option arial wp-100"
    :class="[
      openOptions === true ? 'opened' : '',
      keysSuggestions?.length ? '' : 'no-results',
      mobile ? 'mobile' : '',
    ]"
  >
    <div v-if="isLoadingAIText">
      <li
        class="event-none flex items-center"
        :class="[mobile ? 'justify-center' : 'justify-between']"
      >
        <div class="flex items-center lh-20 fs-14 gray-dark mr-20">
          {{ definedText }}
        </div>
        <div class="fw-500 blue-dark lh-20 fs-14">{{ `${countWords}/60` }}</div>
      </li>
    </div>
    <div v-else-if="shouldShowNoItem">
      <div
        v-for="(typeSuggest, key) in keysSuggestions"
        :key="typeSuggest"
        class="lh-24 list-item"
        :class="[key !== keysSuggestions.length - 1 ? 'mb-8' : '']"
      >
        <template v-if="suggestionByKeyword[typeSuggest]?.length">
          <span class="ttc fw-600-arial lh-24">
            {{ typeSuggest }}
          </span>
          <template v-if="!isLoading">
            <router-link
              v-for="(item, index) in suggestionByKeyword[typeSuggest]"
              :key="index"
              :to="{
                name: 'search-result',
                query: {q: item.input, type: typeSuggest}
              }"
              class="flex items-center"
              :class="[mobile ? ' bd-b-1 bd-gray-new' : '']"
              @click='handleSelect()'
            >
              <img
                :src="`${ASSETS_CDN}/icons/icon-search-gray.svg`"
                class="mr-8 pa-8"
              />
              <span class="black lh-24" v-html="highlightText(item.input)"> </span>
            </router-link>
          </template>
          <template v-else>
            <SkeletonLoading
              class="pa-8 mb-8 ml-8 mr-8"
              v-for="item in 5"
              :key="item"
              :height="'30px'"
              :width="'100%'"
            />
          </template>
        </template>
      </div>
    </div>

    <div v-else>
      <li
        class="event-none flex items-center"
        :class="[mobile ? 'justify-center' : 'justify-between']"
      >
        <div class="flex items-center lh-20 fs-14 gray-dark mr-20">
          <img
            :src="`${ASSETS_CDN}/icons/enter-button.svg`"
            alt="enter button icon"
            class="mr-4"
          />

          {{ definedText }}
        </div>
        <div class="fw-500 blue-dark lh-20 fs-14">{{ `${countWords}/60` }}</div>
      </li>
    </div>
  </ul>
</template>

<script>
import SkeletonLoading from '~/components/globals/Loading/SkeletonLoading'
import { computed, defineComponent, watchEffect, ref, watch } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import {assetCDN, getLengthOfAllItemObject} from '~/helper'

export default defineComponent({
  name: 'NewAutocompleteItem',
  components: {
    SkeletonLoading,
  },
  props: {
    openOptions: {
      default: false,
      type: Boolean,
    },
    searchTerms: {
      default: '',
      type: String,
    },
    newAutoCompleteItem: {
      type: Boolean,
      default: false,
    },
    countWords: {
      type: Number,
      default: 0,
    },
    showBottomBox: {
      type: Boolean,
      default: true,
    },
    mobile: {
      type: Boolean,
      default: true,
    },
  },
  emits:['closeAutoComplete'],
  setup(props, {emit}) {
    // const router = useRouter()
    const term = ref('')
    const ASSETS_CDN = assetCDN()
    const handleSelect = () => {
      // console.log('[search] handleSelect ====')
      emit('closeAutoComplete')
    }
    const store = useStore()

    const suggestionByKeyword = computed(
      () => store.getters['searchStore/getSuggestionKeyword'],
    )

    const isLoading = computed(
      () => store.getters['searchStore/getSuggestionKeywordStatus'],
    )

    const isLoadingAIText = computed(
      () => store.getters['searchStore/getGenerativeTextStatus'],
    )

    const shouldShowNoItem = computed(() =>
      getLengthOfAllItemObject(suggestionByKeyword.value),
    )

    const keysSuggestions = computed(() => {
      const keys = Object.keys(suggestionByKeyword.value)
      return keys
    })

    const definedText = computed(() =>
      isLoadingAIText?.value
        ? 'Please wait until the result is fully generated before doing another search.'
        : `Press the 'Search' icon${
            props.mobile ? `` : ` or hit Enter`
          } to explore now!`,
    )

    watchEffect(() => {
      term.value = props.searchTerms
    })

    const highlightText = (text) => {
      let query = term.value.endsWith(`\\`) ? term.value.slice(0, -1) : term.value
      return text.replace(new RegExp(query, 'gi'), (match) => {
        return '<span class="highlighted">' + match + '</span>'
      })
    }

    watch(
      () => isLoading.value,
      () => {
        'changing'
      },
    )

    return {
      handleSelect,
      highlightText,
      term,
      suggestionByKeyword,
      keysSuggestions,
      ASSETS_CDN,
      isLoading,
      shouldShowNoItem,
      definedText,
      isLoadingAIText,
    }
  },
})
</script>

<style lang="scss" scoped>
ul.custom-option {
  display: none;
  position: relative;
  background: $white;
  box-sizing: border-box;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 12px;
  max-height: 200px;
  overflow: auto;
  max-width: 595px;
  @include phone {
    max-width: unset;
  }

  &.no-results {
    padding: 8px 12px;
  }
  &.opened {
    display: block;
    top: 0px;
    margin-top: 0;
    padding-left: 10px;
    border: 1px solid $gray-medium;
  }
  .list-item {
    display: flex;
    flex-direction: column;
  }

  a {
    padding: 8px;
    border-radius: 6px;
    color: $gray_dark;
    &:hover {
      background: $gray-branding;
    }
    &.event-none {
      pointer-events: none;
      padding: 0;
      cursor: inherit;
    }
  }

  &.mobile {
    max-height: 100%;
    border: none;
    box-shadow: none;
    padding: 20px 12px;
    margin: 0;
    li {
      padding: 0;
    }
  }
}
.highlighted {
  font-weight: 700;
}
</style>
>
