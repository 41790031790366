<template>
  <Teleport to="body">
    <div
      class="search-bar-mobile"
      :class="[props.show ? 'show' : 'hidden']"
      id="search-bar-mobile"
    >
      <div class=''>
        <div class="top-bar pa-16 px-20 flex items-center grid-gap-8 relative z-3 bd-b-1 bd-gray-branding">
          <div class="top-bar__back pa-12" @click.stop="emits('close-search')">
            <img :src="`${ASSETS_CDN}/icons/arrow-back.svg`" alt="back" />
          </div>
          <div
            class="search-bar-mobile__input flex items-start wp-100"
            :class="[searchQuery.length > 0 ? 'active' : '']"
          >
            <textarea
              type="text"
              class="wp-100 fw-500 fs-14 lh-24 mr-8 mt-6"
              id="text-area-search-mobile"
              ref="searchInput"
              :value="searchQuery"
              @keydown.enter="handleSearch"
              @input="$emit('update:search-query', $event.target.value)"
            />
            <div
                 class="search-bar-mobile__input__icon pa-4 h-35 mt-2"
                 @click="handleSearch"
                 :class="searchQuery.length ? 'hand' : ''"
            >
              <img
                :src="`${ASSETS_CDN}/icons/icon-search-black.svg`"
                alt="search"
              />
            </div>
            <button v-if='showDeleteButton' type="button" title="Delete" aria-label="Delete"
                    style='top: 32px; right: 56px; width: 24px;' class="absolute h-24"
                    @click.prevent="deleteSearchText()">
              <img :src="`${ASSETS_CDN}/icons/search/close.svg`" alt='Delete'/>
            </button>
          </div>
        </div>
      </div>
      <div class="result overflow-auto" id="result">
        <SuggestedBox
          v-if="!searchQuery"
          @trendingClick="(data) => $emit('trending-click', data)"
          mobile
        />
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import {computed, onUnmounted, ref, watch} from 'vue'
import SuggestedBox from './SuggestedBoxNew'
import {assetCDN} from '~/helper'
// import {useStore} from 'vuex'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  searchQuery: {
    type: String,
    default: '',
  },
})

const emits = defineEmits([
  'close-search',
  'update:search-query',
  'trending-click',
  'handle-search',
  'focus-search',
  'deleteSearchText'
])

// const store = useStore()
const searchInput = ref(null)
// const showDeleteButton = ref(false)
// const searchQueryInner = ref(props.searchQuery)
const ASSETS_CDN = assetCDN()

const handleSearch = () => {
  if (props.searchQuery.length > 0) {
    document.body.classList.remove('search-popup-open')
    searchInput.value.blur()
    emits('handle-search')
  }
}

// const streamDone = computed(() => store.state.searchStore.streamDone)
//
const showDeleteButton = computed(() => {
  return props.searchQuery.length > 0
})

const deleteSearchText = () => {
  searchInput.value.focus()
  emits('deleteSearchText')
}

watch(
  () => props.show,
  async (show) => {
    if (show === true) {
      document.body.classList.add('search-popup-open')
      setTimeout(() => {
        const element = document.getElementById('text-area-search-mobile')
        element.style.height = element.scrollHeight + 'px'

        // Wait for transition is done
        searchInput.value.focus()

      }, 300)
    } else {
      document.body.classList.remove('search-popup-open')
    }
  },
)

watch(
  () => props.searchQuery,
  () => {
    const element = document.getElementById('text-area-search-mobile')
    element.style.height = '24px'
    element.style.height = element.scrollHeight + 'px'
  },
)

onUnmounted(() => {
  document.body.classList.remove('search-popup-open')
})
</script>

<style lang="scss" scoped>
.search-bar-mobile {
  background-color: white;
  width: 100vw;
  //min-height: 100vh;
  //max-height: 100vh;
  height: 100vh;
  position: fixed;
  top: 100%;
  bottom: 0;
  z-index: 9999;
  transition: top 0.3s ease-in-out;
  overflow: auto;
  &.show {
    //transform: translateY(0);
    top: 0;
  }

  &.hidden {
    //transform: translateY(100%);
    top: 100%
  }

  .top-bar {
    textarea {
      background: $gray-100;
      outline: none;
      resize: none;
      height: 24px;
      max-height: 124px;
      break-before: avoid;
      word-wrap: break-word;
      border: none;
      padding-right: 10px;
    }
  }
  &__input {
    padding: 8px 8px 8px 12px;
    background: $gray-100;
    border: 1px solid $gray-300-new;
    border-radius: 12px;
    &.active {
      border: 1px solid $green-dark;
    }
  }
}
</style>
<style lang='scss'>
body.search-popup-open {
  @include phone() {
    position: fixed; top: 0; bottom: 0; left: 0; overflow-y: hidden; height: 100%;
    touch-action: none;
    /* Other browsers */
    overscroll-behavior: none;
  }
}
</style>
